import Link from "next/link";

export default function DropdownPanelDesktop({
  children,
  session,
  showPrescribingLink,
}) {
  return (
    <div
      className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu"
    >
      <Link
        href="/edit-profile"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        role="menuitem"
      >
        Edit Profile
      </Link>

      <Link
        href="/medical-information-request"
        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        role="menuitem"
      >
        Medical Information Request
      </Link>

      {(showPrescribingLink || Number(session.user.image.role) >= 5) && (
        <Link
          href="/ipp"
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem"
        >
          Product Monograph
        </Link>
      )}

      {children}
    </div>
  );
}
